import { memo } from 'react';
import '../styles/footer.scss';

//import fiocruzImg from '../assets/images/logo_fiocruz.svg';
//import susImg from '../assets/images/SUS.svg';
//import msGovernoImg from '../assets/images/ms_governo.svg';

export function Footer() {
    return (
        <footer id='footer'>
            <div className="grid-sponsors">
                <img src={'https://paineis-esus-bucket-new.s3.sa-east-1.amazonaws.com/footer-resize2.jpg'} className="img-fluid" alt="Fiocruz" style={{maxWidth: '100%'}}/>
            </div>
        </footer>
    )
}

export const MemoizedFooter = memo(Footer);
