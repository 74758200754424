import ReactECharts from 'echarts-for-react'
import { formatAsPercent } from '../../utils'
import './style.scss';

export function Pie({ data }: any) {
    let nome = 'obstetrics-factors';
    let comConsulta = data[1].com_consulta;
    let semConsulta = data[1].sem_consulta;
    let limite = data[1]?.limite;

    let dataGraphic = [
        { value: comConsulta },
        { value: semConsulta }
    ];

    const options = {
        color: ['#343131', '#53EFBF'],
        tooltip: {
            trigger: "item",
            formatter: "({d}%)"
        },
        series: [
            {
                name: nome,
                type: "pie",
                radius: "80%",
                center: ["50%", "50%"],
                animationDuration: 5000,
                label: {
                    show: true,
                    position: 'center',
                    fontSize: '16',
                    fontWeight: 'bold'
                },
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)"
                },
                labelLine: {
                    show: false
                },
                data: dataGraphic
            }
        ]
    };

    return (
        <div className='pie'>
            <ReactECharts
                option={options}
                style={{
                    width: "126px",
                    height: "126px"
                }}
                opts={{ renderer: 'svg' }}
            />
            <div className='data-info d-flex flex-column align-items-center'>
                <span className='porcentagem'>{formatAsPercent(comConsulta)}</span>
                <span className='nomeGrafico'>{data[0]}</span>
                <span className='nomeGrafico'>{limite}</span>
            </div>
        </div>
    )
}
